import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"coupongroups",
        "id":"coupongroup_id",
        "subidname":"coupongroup_name",
        "options":["edit","delete","insert"],
        "nameSingle":"coupon set",
        "nameMultiple":"coupon sets",
        "extraoptions":[
            {
                "name":"Coupons",
                "page":"/coupons",
            }
        ],
        "fields":{
            "field1":{
                "name":"Name",
                "field":"coupongroup_name",
                "type":"TextInput",
                "required":true,
                "list":true
            }
        }
    }
}